<template>
    <div class="min-h-screen flex flex-col">
        <HeaderImpersonate></HeaderImpersonate>

        <header class="pl-6 pr-6 flex justify-between items-center h-menu relative z-10" :class="getTheme.menu.class">
            <div class="sm:flex sm:items-center">
                <NuxtLink class="cursor-pointer" :to="{name: 'index'}">
                    <logos-full class="w-[72px] h-[1.25rem]"></logos-full>
                </NuxtLink>
            </div>

            <div class="text-tangerine text-sm font-medium tracking-tight flex-1 ml-12">{{ currentFormName }}</div>

            <div class="flex">
                <c-button
                    v-if="!isLoggedIn" class="ml-5 bg-tangerine text-white text-body-14"
                    :to="{name: 'mon-compte'}">
                    Mon compte
                </c-button>

                <div v-else class="ml-5 sm:ml-0">
                    <div class="flex gap-5 sm:gap-3 mr-10 sm:mr-0 md:mr-0 relative cursor-default leading-[4rem]">
                        <NuxtLink :to="{name: 'mon-compte-mon-panier'}"
                                  class="flex hover:text-tangerine"
                                  exact-active-class="link-is-active"
                        >
                            <icons-shopping-cart-outline :active="useUserStore().hasInShoppingCart" class="mt-0.5 mr-1.5 w-6"></icons-shopping-cart-outline>
                            <span class="block sm:hidden">Mon panier</span>
                        </NuxtLink>

                        <NuxtLink :to="{name: 'mon-compte'}"
                                  class="flex hover:text-tangerine"
                                  active-class="link-is-active"
                        >
                            <icons-user-outline class="mt-0.5 mr-1.5 w-6"></icons-user-outline>
                            <span class="block sm:hidden">{{ prenom }}</span>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </header>

        <div class="bg-grey100 flex flex-1">
            <slot></slot>
        </div>

        <c-modal v-model="showTimeOut" @input="emits('input', false)" :closable="false"
                 width="25vw">
            <div class="w-full bg-white rounded-xl p-7">
                <div class="flex justify-center text-center mb-5 text-xl font-medium px-12">
                    Nous sommes désolés mais le temps est écoulé 😥
                </div>

                <div class="flex justify-center text-center mb-5 text-grey7 text-base font-normal">
                    Vous allez être redirigé vers votre espace client pour reprendre le parcours d'achat.
                </div>

                <div class="flex justify-center items-center gap-6">
                    <c-button class="text-white bg-tangerine text-sm font-medium flex items-center" @click="closeModal">
                        J'ai compris
                        <icons-arrow-narrow-right-solid class="w-4 h-4 ml-2"></icons-arrow-narrow-right-solid>
                    </c-button>
                </div>
            </div>
        </c-modal>

        <c-modal v-model="showWrongInfo" @input="emits('input', false)" :closable="false" width="25vw">
            <div class="w-full bg-white rounded-xl p-7">
                <div class="flex justify-center text-center text-grey7 text-base font-normal">
                    Les informations des différents formulaires sont pré-remplies en fonction des éléments entrés lors
                    de votre précédent investissement.
                </div>

                <div class="flex justify-center text-center mb-5 text-base font-medium">
                    Merci de les vérifier et les corriger si celles-ci ne sont plus à jour.
                </div>

                <div class="flex justify-center items-center gap-6">
                    <c-button
                        class="text-white bg-tangerine text-sm font-medium flex items-center"
                        @click="closeCloneModal">
                        J'ai compris
                        <icons-arrow-narrow-right-solid class="w-4 h-4 ml-2"></icons-arrow-narrow-right-solid>
                    </c-button>
                </div>
            </div>
        </c-modal>

        <ChatFloating></ChatFloating>
    </div>
</template>

<script setup>
import { useUserStore } from '~/store/user';
import { useReservationStore } from '~/store/reservation';
import {storeToRefs} from "pinia";

const route = useRoute()
const router = useRouter()
const userStore = useUserStore();
const reservationstore = useReservationStore();
const emits = defineEmits(['input']);

const {isLoggedIn, prenom} = storeToRefs(userStore);

const currentFormName = reservationstore.getCurrentFormName;

const selected = ref(false);
const timerInterval = ref(null);
const timerValue = ref("");
const timerEnded = ref(false);
const vente = ref(null);
const bienVente = ref(null);
const chatAutoOpenTimeout = ref(null);

function pageChanged() {
    clearTimeout(chatAutoOpenTimeout.value);

    chatAutoOpenTimeout.value = setTimeout(() => {
        useNuxtApp().$emit('open-chat')
    }, 60 * 1000); // = 1 minute
}

function closeModal() {
    router.push({'name': 'mon-compte-mon-bien'})
}

function closeCloneModal() {
    useNuxtApp().$axios.post("/ma-reservation/set-uncloned").then(({data}) => {
        vente.value = data;
    }).catch((e) => {
        console.error(e);
    });
}

function fetchVente() {
    return new Promise(resolve => {
        useNuxtApp().$axios.get("/mon-compte/vente/current").then(({data}) => {
            vente.value = (data != null && data.length > 0) ? data[0] : null; //une seul vente en simulaté caramel en V1

            if (vente.value === null) {
                bienVente.value = null;
                resolve();

                return;
            }

            useNuxtApp().$axios.post("/catalogue/lots-by-slug", {lots: [vente.value.lot]}).then(({data}) => {
                bienVente.value = data.length > 0 ? data[0] : null;
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                resolve();
            });

        }).catch((e) => {
            console.error(e);
            resolve();
        });
    });
}

function startCountDown() {
    if (process.client === false) {
        return;
    }
    clearInterval(timerInterval.value);

    let endAt = useNuxtApp().$moment(vente.value.expirationDate);

    timerInterval.value = setInterval(() => {
        let timeLeft = useNuxtApp().$moment.duration(endAt.diff(useNuxtApp().$moment()));

        if (timeLeft.asSeconds() < 0) {
            timerValue.value = "0:00:00";
            timerEnded.value = true;

            return;
        }

        timerEnded.value = false;
        timerValue.value = useNuxtApp().$moment(timeLeft.asHours()) + ':' + useNuxtApp().$moment(timeLeft.asMilliseconds()).format('mm:ss');
    }, 500);
}

function logout() {
    let userStore = useUserStore();
    userStore.logout();

    window.location.href = useNuxtApp().$axios.defaults.baseURL + "/logout";
}

onMounted(() => {
    fetchVente();
    useNuxtApp().$off("stepUpdateDossierReservation");
    useNuxtApp().$on("stepUpdateDossierReservation", pageChanged)
})

onUnmounted(() => {
    clearTimeout(chatAutoOpenTimeout.value);
    clearInterval(timerInterval.value);
});

const getTheme = computed(() => {
    return route.theme == null ? themes.default : themes[route.meta.theme];
});

const currentStep = computed(() => {
    if (vente.value != null) {
        switch (vente.value.status) {
            case 'reservation':
                return 1;
            case 'validation_client':
                return 2;
            case 'signature':
                return 3;
            case 'upload_docs':
                return 4;
            case 'validation_bo':
                return 5;
            case 'signature_mandat_gestion':
                return 6
            case 'paiement':
                return 7;
            case 'signature_relation': // todo: delete this line when financement is ready (ne doit pas être a 8, mais à + que ça, intervient après l'appel courtier
            case 'declaration_origine_fonds':
            case 'appel_courtier':
                return 8;
            case 'date_clefs':
                return 8;
            default:
                return 0;
        }
    }

    return 0;
});

const showTimeOut = computed(() => {
    return timerEnded.value && [1, 2, 3].includes(currentStep.value)
});

const showWrongInfo = computed(() => {
    return vente.value != null && vente.value.cloned === true
});

watch(route, () => {
    pageChanged();
}, {deep: true, immediate: true});

watch(vente, () => {
    if (vente.value == null) {
        clearInterval(timerInterval.value);
        timerValue.value = "";

        return;
    }

    startCountDown();
}, {immediate: true});

const themes = {
    default: {
        menu: {
            class: 'bg-white shadow-[inset_0px_-1px_0px_#EBE7E6]'
        },
        contact: {
            class: 'border-black border-opacity-10'
        },
        account: {
            separator: {
                class: 'border-grey6'
            }
        }
    },
    forest: {
        menu: {
            class: 'text-white bg-forest border-[#38726A]'
        },
        contact: {
            class: 'border-forest-light border-opacity-10'
        },
        account: {
            separator: {
                class: 'border-[#38726A]'
            }
        }
    },
    blue: {
        menu: {
            class: 'bg-blue5 border-grey6'
        },
        contact: {
            class: 'border-black border-opacity-10'
        },
        account: {
            separator: {
                class: 'border-grey6'
            }
        }
    }
};
</script>

<style scoped>

</style>
